$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
    margin: 0 5% 0 10%;
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}


.contain-img img {
    width:  100%;
    height: 15rem;
    object-fit: contain;
}


.contain-img-background img {
    width:  100%;
    height: 15rem;
    object-fit: contain;
    background-color: $secondary;    
}


.cover-img img {
    width:  100%;
    height: 15rem;
    object-fit: cover;
}

#socials {
  display: flex;
}

#socials a {
    color: black;
    margin-right: 1em;
}
